import { sendToZendesk } from "services/zendeskService"
import { inquiryTicketBody } from "../utils/inquiryTicketTemplate"

const generateTags = () => {
  let templateTags = ["maersk", "inquiry"]

  if (process.env.GATSBY_ENV !== "production") templateTags.push("test")

  return { tags: templateTags }
}

const generateInquiryTicket = (ticketData, template) => {
  const TEST_PREFIX = process.env.GATSBY_ENV !== "production" ? "[TEST] " : ""

  return {
    type: "inquiry",
    subject: `${TEST_PREFIX}Contact Us Inquiry: ${ticketData?.subject.value}`,
    requester: {
      name: ticketData?.fullName,
      email: ticketData?.email,
    },
    comment: {
      body: template(ticketData),
    },
    tags: ticketData.tags,
  }
}

export const sendInquiry = async ({
  values,
  callback,
  setErrors,
  errorCallback,
}) => {
  try {
    let { tags } = generateTags()
    let inquiryTicket = generateInquiryTicket(
      { ...values, tags },
      inquiryTicketBody
    )
    await sendToZendesk(inquiryTicket || {}, [])
    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback()
    setErrors({ sendInquiry: { code: error.code, message: error.message } })
  }
}
