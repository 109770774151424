import React, { Fragment } from "react"

const FlexmedOtherBenefitsTable = () => {
  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr className="is-primary">
            <th className="has-text-white has-text-centered"> Benefit Type </th>
            <th className="has-text-white has-text-centered">
              Item for Reimbursement
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "middle" }}>Optical Benefit</td>
            <td>
              <ul>
                <li>Optical - Consultation</li>
                <li>Optical - Saline Solution</li>
                <li>Optical - Prescription eyewear or contact lenses</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "middle" }}>Medical Benefit</td>
            <td>
              <ul>
                <li>Medical - Consultation</li>
                <li>Medical - Laboratory test</li>
                <li>Other medicines</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "middle" }}>Dental Benefit</td>
            <td>
              <ul>
                <li>Dental - Other treatments</li>
                <li>Dental - Others</li>
                <li>Dental - Fillings</li>
                <li>Dental - Cleaning</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: "middle" }}>Wellness Benefit</td>
            <td>
              <ul>
                <li>Wellness - Gym membership</li>
                <li>Wellness - Pilates membership</li>
                <li>Wellness - Yoga membership</li>
                <li>Wellness - Bowling membership</li>
                <li>Wellness - Other membership</li>
                <li>Wellness - Whey supplements</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export default FlexmedOtherBenefitsTable
