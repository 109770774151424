import React, { Fragment } from "react"
import mechanics from "./utils/flexmedMechanicsTable.json"

const FlexmedMechanicsTable = () => {
  let headers = [
    "Receipt Coverage",
    "Employee Submission Deadline",
    "Payout Date",
  ]
  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr className="is-primary">
            {headers.map(header => (
              <th className="has-text-white has-text-centered">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {mechanics.map((mechanics, index) => (
            <tr key={index}>
              <td className="has-text-centered">{mechanics.coverage}</td>
              <td className="has-text-centered">{mechanics.deadline}</td>
              <td className="has-text-centered">{mechanics.payout}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="is-size-6">
        <span className="has-text-weight-bold">NOTE:</span> Due to quarantine
        restrictions, submission of hard copy documents is suspended until
        further notice. Please keep these documents for submission once office
        work resumes.
      </p>
    </Fragment>
  )
}

export default FlexmedMechanicsTable
