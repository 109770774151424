import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/maersk-pbm/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
import ContactUs from '../components/StaticPages/HelpCenter/ContactUs';
import receiptPlaceholder from "../../static/images/receipt_placeholder.png";
import FlexmedOtherBenefitsTable from "../components/FlexMed/FlexmedOtherBenefitsTable";
import FlexmedMechanicsTable from "../components/FlexMed/FlexmedMechanicsTable";
import { EmployeeIssueTable, ReceiptIssueTable, ItemIssueTable, RxIssueTable } from "../components/FlexMed/FlexMedReimbursementIssuesTable";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Container isCentered mdxType="Container">
      <SEO title="Help Center" mdxType="SEO" />
      <h1>{`How can we help you?`}</h1>
      <p>{`Search through our help center for guides and quick answers`}</p>
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`Quick Guides`}</h4>
      <Collapsible title="What is FlexMed?" mdxType="Collapsible">
        <p>{`FlexMed allows employees to purchase medicines, medical services, and wellness services and have it reimbursed using their Php10K annual Medical Allowance. Visit  `}<Link to="/flexmed" className="has-text-weight-bold has-text-primary" mdxType="Link">{`maersk.medgrocer.com/flexmed`}</Link>{` to check what’s covered and what’s required to avail.`}</p>
      </Collapsible>
      <h4>{`Employee Eligibility`}</h4>
      <Collapsible title="Am I eligible for the program?" mdxType="Collapsible">
        <p>{`You are eligible for FlexMed if you are a Maersk employee under local contract and on a regular status. For any inquiries on the status of your eligibility, please consult HR.`}</p>
      </Collapsible>
      <Collapsible title="Are my dependents eligible for FlexMed?" mdxType="Collapsible">
        <p>{`Yes, dependents such as immediate family members and/or declared domestic partner are covered under this benefit.`}</p>
        <b>Immediate family is defined as</b>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`For single employees: parents, siblings, or grandparents`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`For single parents and unmarried employees: parents, children, domestic partner, or common law spouse`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`For married employees: spouse or children`}</p>
          </li>
        </ul>
      </Collapsible>
      <h4>{`FlexMed: Claiming Reimbursements`}</h4>
      <Collapsible title="What benefits are covered under FlexMed?" mdxType="Collapsible">
        <p>{`FlexMed covers medical expenses such as consultations, over-the-counter medicines, vitamins, supplements, prescription drugs, and vaccines. Optical, dental, and wellness services such as reading glasses, braces, sealants, gym, and pilates memberships are also covered.
Visit `}<Link to="/flexmed" className="has-text-weight-bold has-text-primary" mdxType="Link">{`maersk.medgrocer.com/flexmed`}</Link>{` to check what’s covered and what’s required to avail.`}</p>
      </Collapsible>
      <Collapsible title="How do I claim reimbursements for my medical or wellness expenses?" mdxType="Collapsible">
        <p>{`To claim reimbursements:`}</p>
        <ol>
          <li parentName="ol">{`Select “Upload Receipts” in `}<Link to="/" className="has-text-weight-bold has-text-primary" mdxType="Link">{`maersk.medgrocer.com`}</Link></li>
          <li parentName="ol">{`Upload official receipts and prescription if applicable`}</li>
          <li parentName="ol">{`If you are reimbursing an expense for your dependent, please upload other required documents`}</li>
          <li parentName="ol">{`[SUSPENDED UNTIL RETURN TO WORK]`}{` Submit your original receipts and required documents at the 7th floor dropbox beside the payroll area on or before the submission deadline.`}<ol type='a'>
    <li> Print and attach the email confirmation of your approved claims received from maersk.med@medgrocer.com </li>
    <li> The email confirmation should contain the following: </li>
        <ol type="i">
            <li> Reference number </li>
            <li> AMP ID </li>
            <li> List of approved medicines matching the original receipts for submission </li>
        </ol>
            </ol>
            <strong>NOTE:</strong> Due to quarantine restrictions, submission of hard copy documents is suspended until further notice. Please keep these documents for submission once office work resumes.</li>
        </ol>
      </Collapsible>
      <Collapsible title="How much is my coverage for my medical allowance?" mdxType="Collapsible">
        <p>{`Medical allowance worth Php 10k is provided every year to eligible employees. This allowance will be prorated for the applicable year based on the employee’s tenure upon regularization.`}</p>
        <p>{`If the regularization of the employee is within the year, the allowance is computed from the date of authorization. `}</p>
      </Collapsible>
      <Collapsible title="Do I need a prescription to request for reimbursements?" mdxType="Collapsible">
        <p>{`Yes. A valid prescription named to the employee or their dependent is required to process certain reimbursement claims. The following requests require a prescription copy:`}</p>
        <ul>
          <li parentName="ul">{`Ethical drugs (e.g. prescription drugs, antipsychotics/antidepressants, vaccines)`}</li>
          <li parentName="ul">{`Eyewear (i.e. reading glasses)`}</li>
          <li parentName="ul">{`Laboratory tests`}</li>
        </ul>
      </Collapsible>
      <Collapsible title="What is a valid receipt?" mdxType="Collapsible">
        <p>{`A valid receipt is any official receipt issued by a BIR-registered pharmacy or company. Official receipts issued outside the Philippines will be accepted, however, the amount should be converted to Philippine peso based on the date that the item was purchased. `}</p>
        <p>{`A valid receipt contains:`}</p>
        <img src={receiptPlaceholder} alt="next" width="70%" height="80%" />
      </Collapsible>
      <Collapsible title="What is a full or partial receipt?" mdxType="Collapsible">
        <p>{`Full or partial receipt refers to whether you would like to reimburse your full receipt or only selected items from your receipt.`}</p>
        <p>{`If you wish to reimburse all items on your receipt, select "Full receipt", fill up the receipt details, and encode the receipt subtotal. `}</p>
        <p>{`If you wish to reimburse only certain items on your receipt, select "Partial receipt",  fill up the receipt details, and encode the items you want reimbursed.`}</p>
      </Collapsible>
      <Collapsible title="I can’t find my purchased items on the dropdown list." mdxType="Collapsible"> 
        <div id="other-medicines">
If you can't find your purchased items in the dropdown list, check if an "Other Brand" option is available. If no "Other Brand" option is available, select the option below that fits the benefit you're filing:
          <FlexmedOtherBenefitsTable mdxType="FlexmedOtherBenefitsTable" />
        </div>
      </Collapsible> 
      <Collapsible title="How do I know if my reimbursement request is approved?" mdxType="Collapsible">
        <p>{`You will receive an email regarding your reimbursement claim within 1-2 business days after confirmation of your submission.`}</p>
      </Collapsible>
      <Collapsible title="How long is the processing time for reimbursements?" mdxType="Collapsible">
        <p>{`Approved reimbursement claims will be paid out on the first payroll period of the following month. For reference:`}</p>
        <FlexmedMechanicsTable mdxType="FlexmedMechanicsTable" />
      </Collapsible>
      <Collapsible title="I submitted my reimbursement request but gave incomplete documents. Can I still submit my documents and update my request?" mdxType="Collapsible">
Please re-submit your request through <Link to="/" className="has-text-weight-bold has-text-primary" mdxType="Link">maersk.medgrocer.com</Link> together with all supporting documents to ensure faster processing.
      </Collapsible>
      <Collapsible title="Can I reimburse an online purchase?" mdxType="Collapsible">
        <p>{`Yes. For online purchases, please submit the following documents aside from standard requirements:`}</p>
        <ul>
          <li parentName="ul">{`Transaction form`}</li>
          <li parentName="ul">{`If bank payment: Deposit or payment slip`}</li>
          <li parentName="ul">{`If credit card payment: Credit card billing statement`}</li>
        </ul>
        <p>{`Please print all online receipts, transaction slips, and supporting documents and submit these to the 7th floor payroll area.`}</p>
      </Collapsible>
      <Collapsible title="How will I receive my reimbursements?" mdxType="Collapsible">
        <p>{`Your approved reimbursement claim will be credited to your payroll account.`}</p>
      </Collapsible>
      <Collapsible title="How do I inquire about my remaining balance for the year?" mdxType="Collapsible">
        <p>{`You may email us at `}<a href="mailto:maersk.med@medgrocer.com" className="has-text-weight-bold has-text-primary">{`maersk.med@medgrocer.com`}</a>{` for inquiries.`}</p>
      </Collapsible>
      <Collapsible title="I lost my receipt, can I still request for reimbursement?" mdxType="Collapsible">
        <p>{`Physical copies of your receipts are required for your reimbursement claim to be processed.`}</p>
      </Collapsible>
      <h4>{`FlexMed: Reimbursement Issues`}</h4>
      <Collapsible title="I haven't received my verification email, what should I do?" mdxType="Collapsible">
        <p>{`Please check the spam/junk folder in your email inbox as your verification email may have been filed there. If you did not receive your verification email two business days after receiving your confirmation email, please email us with your request reference number at `}<a href="mailto:maersk.med@medgrocer.com" className="has-text-weight-bold has-text-primary">{`maersk.med@medgrocer.com`}</a></p>
      </Collapsible>
      <Collapsible title="My reimbursement was rejected due to an issue. What do I do?" mdxType="Collapsible">
        <p>{`Your reimbursement may have been rejected due to a breach in eligibility rules for reimbursement requests. Please find the applicable issue below with the possible reasons for rejection and what your next steps should be.`}</p>
        <p>{`Should you need any further clarification regarding your reimbursement, please reply to your acknowledgement email with your request reference number.`}</p>
      </Collapsible>
      <Collapsible title="What is an employee related issue?" mdxType="Collapsible">
        <p>{`The following are the possible reasons for rejection due to an employee related issue and what your next steps should be:`}</p>
        <EmployeeIssueTable mdxType="EmployeeIssueTable" />
      </Collapsible>
      <Collapsible title="What is a receipt related issue?" mdxType="Collapsible">
        <p>{`The following are the possible reasons for rejection due to a receipt related issue and what your next steps should be:`}</p>
        <ReceiptIssueTable mdxType="ReceiptIssueTable" />
      </Collapsible>
      <Collapsible title="What is a item related issue?" mdxType="Collapsible">
        <p>{`The following are the possible reasons for rejection due to an item related issue and what your next steps should be:`}</p>
        <ItemIssueTable mdxType="ItemIssueTable" />
      </Collapsible>
      <Collapsible title="What is a Rx related issue?" mdxType="Collapsible">
        <p>{`The following are the possible reasons for rejection due to a prescription related issue and what your next steps should be:`}</p>
        <RxIssueTable mdxType="RxIssueTable" />
      </Collapsible>
      <h4>{`During Quarantine`}</h4>
      <Collapsible title="When do I have to submit the physical documents of my reimbursement?" mdxType="Collapsible">
        <p>{`Due to quarantine restrictions, submission of hard copy documents is suspended until further notice. Please wait for Maersk HR's announcement on deadlines or contact them directly for document submission clarifications.`}</p>
      </Collapsible>
      <h4>{`Special Requests`}</h4>
      <Collapsible title="Can I have my reimbursement transferred to me outside of the list of reimbursement options?" mdxType="Collapsible">
        <p>{`For security reasons, reimbursements may only be transferred through your payroll account.`}</p>
      </Collapsible>
      <Collapsible title="Can I have my reimbursement sent to an account that is not mine?" mdxType="Collapsible">
        <p>{`For your protection, your reimbursement may only be transferred to an account named to you.`}</p>
      </Collapsible>
      <Collapsible title="Can you deliver my reimbursement to my office in cash or check?" mdxType="Collapsible">
        <p>{`For security reasons, reimbursements may only be transferred through your payroll account. `}</p>
      </Collapsible>
      <h4>{`Privacy`}</h4>
      <Collapsible title="What information do you share?" mdxType="Collapsible">
        <p>{`Only our pharmacists processing your request have access to your medical information to ensure your reimbursements are properly assessed. We may use anonymized data for analytics to improve our services or collaborate with partners.`}</p>
      </Collapsible>
      <Collapsible title="How do I contact the data privacy officer?" mdxType="Collapsible">
        <p>{`MedGrocer’s Data Privacy Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following information:`}</p>
        <p>{`MedGrocer 24F Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City `}<br />{`
Contact Number: 02 8332 3768 `}<br />{`
Email address: `}<a href="mailto:dpo@medgrocer.com" className="has-text-weight-bold has-text-primary">{`dpo@medgrocer.com`}</a></p>
      </Collapsible>
      <h4>{`Regulatory`}</h4>
      <Collapsible title="What is MedGrocer?" mdxType="Collapsible">
        <p>{`MedGrocer is an FDA-licensed online pharmacy. Simply order your medicines online and we’ll deliver them to you.`}</p>
      </Collapsible>
      <ContactUs mdxType="ContactUs" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      