import React, { Fragment } from "react"

import flexmedIssuesTableContent from "./utils/helpCenter/flexmedIssuesTableContent.json"

let employeeIssue = flexmedIssuesTableContent.filter(
  issue => issue.issue === "employee"
)
let receiptIssue = flexmedIssuesTableContent.filter(
  issue => issue.issue === "receipt"
)
let itemIssue = flexmedIssuesTableContent.filter(
  issue => issue.issue === "item"
)
let rxIssue = flexmedIssuesTableContent.filter(issue => issue.issue === "rx")

export const EmployeeIssueTable = () => {
  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr className="is-primary">
            <th className="has-text-white has-text-centered">
              Reasons for ineligibility
            </th>
            <th className="has-text-white has-text-centered">Next steps</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                {employeeIssue[0].reasons.map(reason => {
                  return <li>{reason}</li>
                })}
              </ul>
            </td>
            <td>
              <ul>
                {employeeIssue[0].nextSteps.map(nextSteps => {
                  return <li>{nextSteps}</li>
                })}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export const ReceiptIssueTable = () => {
  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr className="is-primary">
            <th className="has-text-white has-text-centered">
              Reasons for ineligibility
            </th>
            <th className="has-text-white has-text-centered">Next steps</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                {receiptIssue[0].reasons.map(reason => {
                  return <li>{reason}</li>
                })}
              </ul>
            </td>
            <td>
              Reupload your request with the receipt you want reimbursed. To
              ensure faster processing, make sure:
              <ul>
                {receiptIssue[0].nextSteps.map(nextSteps => {
                  return <li>{nextSteps}</li>
                })}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export const ItemIssueTable = () => {
  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr className="is-primary">
            <th className="has-text-white has-text-centered">
              Reasons for ineligibility
            </th>
            <th className="has-text-white has-text-centered">Next steps</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                {itemIssue[0].reasons.map(reason => {
                  return <li>{reason}</li>
                })}
              </ul>
            </td>
            <td>
              <ul>
                {itemIssue[0].nextSteps.map(nextSteps => {
                  return <li>{nextSteps}</li>
                })}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}

export const RxIssueTable = () => {
  return (
    <Fragment>
      <table className="table">
        <thead>
          <tr className="is-primary">
            <th className="has-text-white has-text-centered">
              Reasons for ineligibility
            </th>
            <th className="has-text-white has-text-centered">Next steps</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                {rxIssue[0].reasons.map(reason => {
                  return <li>{reason}</li>
                })}
              </ul>
            </td>
            <td>
              <ul>
                {rxIssue[0].nextSteps.map(nextSteps => {
                  return <li>{nextSteps}</li>
                })}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Fragment>
  )
}
